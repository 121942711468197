import styled from 'styled-components';

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 40px;
`;

export const SystemSyncDate = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 10px;
  font-weight: 400;
  margin-top: 8px;
`;

export const NextSync = styled.div`
  color: #828d9a;
`;

export const ChartTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PeriodControls = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  > *:last-child {
    min-width: 120px;
  }
`;

export const PeriodAndArrows = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
`;

export const Chart = styled.div`
  min-height: 283px;
  height: 283px;
  width: 100%;
`;

export const ChartLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ChartLegendLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ChartLegendLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 12px;
  font-weight: 400;
  color: #1d1d35;
`;

export const HomeImageAndStats = styled.div`
  position: relative;
  width: 100%;
  min-height: 120px;
`;

export const HomeImage = styled.img`
  width: 70%;
  height: auto;
`;

const Annotation = styled.div`
  position: absolute;
  text-align: center;
  z-index: 1;
`;

const AnnotationLine = styled.div`
  width: 1px;
  position: absolute;
  z-index: 1;
`;

export const SolarAnnotation = styled(Annotation)`
  left: calc(0.26 * 70%);
  top: 8%;
`;

export const SolarLine = styled(AnnotationLine)`
  background-color: #ffbd13;
  left: calc(0.25 * 70%);
  top: 8%;
  height: 35%;
`;

export const ConsumedAnnotation = styled(Annotation)`
  left: calc(0.66 * 70%);
  top: 8%;
`;

export const ConsumedLine = styled(AnnotationLine)`
  background-color: #c8c8d3;
  left: calc(0.65 * 70%);
  top: 8%;
  height: 33%;
`;

export const AnnotationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
`;

export const AnnotationSubtitle = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
`;

export const AnnotationBorderRect = styled.div`
  position: absolute;
  left: calc(0.84 * 70%);
  width: 37%;
  height: 120px;
  bottom: 20%;
  border-radius: 8px;
  border-left: 1px dashed #ffbd13;
  border-top: 1px dashed #ffbd13;
  border-right: 1px dashed #009a47;
  border-bottom: 1px dashed #009a47;
  border-bottom-left-radius: 0;
`;

const RectArrow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RectLeftArrow = styled(RectArrow)`
  left: -6px;
  top: 30%;
`;

export const RectTopArrow = styled(RectArrow)`
  left: 70%;
  top: -6px;
`;

export const RectRightArrow = styled(RectArrow)`
  right: -6px;
  top: 70%;
`;

export const RectBottomArrow = styled(RectArrow)`
  left: 30%;
  bottom: -6px;
`;

export const AnnotationRect = styled.div`
  position: absolute;
  left: calc(0.86 * 70%);
  width: 37%;
  height: 120px;
  bottom: 20%;
`;

export const AnnotationRectInner = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  padding-left: 15%;
  display: grid;
  grid-template-columns: min-content min-content;
  row-gap: 16px;
  column-gap: 24px;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  justify-content: center;
`;
