import * as config from '@services/service.config';
import { post, put, get, deleteRequest, patch } from '@services/api/base';
import { SearchPartial } from '@components/Project/ProjectView/types';
import { dataBankService } from '@services/service.config';
import { FormLayoutDTO, CreateFormColumnDto } from '@types';
import { generateParams } from './helpers';
import { SocketFallbackRequest } from './types';

export const downloadFile = (fileId: number, companyId: number) =>
  `${config.databankDownloadFileURL(fileId)}?accessToken=${
    JSON.parse(localStorage.getItem('token')).accessToken.token
  }&companyId=${companyId}`;

export const getPlayMedialFileUrl = (fileId: number, companyId: number) => {
  return `${config.databankPlayMediaURL(fileId)}?accessToken=${JSON.parse(localStorage.getItem('token')).accessToken.token}&companyId=${companyId}`;
};

const databankApi = {
  fetchForm: (formId: number) => get(config.formBuilderSingleUrl(formId)),
  fetchFormTemplates: (...rest: Parameters<typeof config.getFromTemplaterUrl>) =>
    get(config.getFromTemplaterUrl(...rest)),
  createFormTemplates: (data: any) => post(config.getFromTemplaterUrl(), data),
  deleteFormTemplate: (...rest: Parameters<typeof config.deleteFormTemplateUrl>) =>
    deleteRequest(config.deleteFormTemplateUrl(...rest)),
  fetchUsers: (ids: number[], companyId?: number) => get(config.getUserServiceURLwithIn(ids, companyId)),
  fetchFolders: (...rest: Parameters<typeof config.dataBankFetchFolderURL>) =>
    get(config.dataBankFetchFolderURL(...rest)),
  fetchLabelsPagination: (...rest: Parameters<typeof config.getLabelUrl>) => get(config.getLabelUrl(...rest)),
  fetchLabelsAll: (...rest: Parameters<typeof config.getLabelsAllURL>) => get(config.getLabelsAllURL(...rest)),
  updateFile: (fileId: number, body: any) => patch(`${dataBankService}/files/${fileId}`, body),
  bulkUpdateFiles: ({
    projectId,
    fileIds,
    sharedWithPortal
  }: {
    projectId: number;
    fileIds: number[];
    sharedWithPortal: boolean;
  }) => post(`${dataBankService}/files/bulk-update`, { projectId, fileIds, sharedWithPortal }),
  updateRecordFile: ({
    recordId,
    fileId,
    sharedWithPortal
  }: {
    recordId: number;
    fileId: number;
    sharedWithPortal: boolean;
  }) => put(`${dataBankService}/projects/${recordId}/files/${fileId}/update`, { sharedWithPortal }),
  deleteFile: (...rest: Parameters<typeof config.databankDeleteFileURL>) =>
    deleteRequest(config.databankDeleteFileURL(...rest)),
  deletePublicFile: (...rest: Parameters<typeof config.databankDeleteFilePublicURL>) =>
    deleteRequest(config.databankDeleteFilePublicURL(...rest)),
  createForm: (formData: any, companyId: number) => post(config.formBuilderUrl(companyId), formData),
  updateFormLayout: (formId: number, companyId: number, layout: FormLayoutDTO[]) =>
    patch(`${config.formBuilderUrl()}/${formId}/layout?companyId=${companyId}`, layout),
  updateFormColumn: (formId: number, columnId: number, companyId: number, dto: Partial<CreateFormColumnDto>) =>
    patch(`${config.formBuilderUrl()}/${formId}/columns/${columnId}?companyId=${companyId}`, dto),
  createFormColumn: (formId: number, companyId: number, dto: CreateFormColumnDto) =>
    post(`${config.formBuilderUrl()}/${formId}/columns?companyId=${companyId}`, dto),
  formSocketFallback: (formId: number, body: SocketFallbackRequest) => post(config.formSocketFallbackUrl(formId), body),
  updateForm: (formData: any, formId: number, companyId: number) =>
    patch(config.databankFormUrl(formData.projectId as number, formId, companyId), formData),
  fetchStorageInfo: (body: any) => post<{ free: number; used: number }>(config.databankStorageInfoURL(), body),
  createLabel: (data: any, search?: SearchPartial) => post(config.labelURL, data, generateParams({ search })),
  updateLabel: (id: Parameters<typeof config.updateLabelURL>[0], data: any) => put(config.updateLabelURL(id), data),
  removeLabel: (id: number) => deleteRequest(`${config.labelURL}/${id}`),
  uploadFile: (projectId: Parameters<typeof config.databankUploadURL>[0], body: any) =>
    post(config.databankUploadURL(projectId), body),
  downloadMultiple: (data: any, ...rest: Parameters<typeof config.dataBankDownloadMultipleURL>) =>
    post(config.dataBankDownloadMultipleURL(...rest), data, {
      axiosParams: { responseType: 'arraybuffer' }
    }),
  fetchFormByProjectId: (withTemplates = false, ...params: Parameters<typeof config.fetchFormsByProjectIdUrl>) =>
    get(`${dataBankService}/projects/${params[0]}/forms?withTemplates=${withTemplates}`),
  downloadSingleFile: (id: number, companyId: number) =>
    get(downloadFile(id, companyId), { axiosParams: { responseType: 'arraybuffer' } })
};

export const downloadForm = (formId: number) => {
  const savedToken = localStorage.getItem('token') || '';

  return config.databankDownloadFormURL(formId, JSON.parse(savedToken).accessToken.token);
};

export default databankApi;
