import { useForm } from '@kit/components/Form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form as TForm, FormLayout, ProjectProperty, File } from '@generated/types/graphql';
import { useFullForm } from '@hooks/useForms';
import { debounce, get } from 'lodash';
import { useUpdateFormFieldValue } from '@hooks/documents/forms/useUpdateFormFieldValue';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { ProjectDetail, PropertyType } from '@types';
import { getAdditionalPropertyValue } from '@utils/properties';
import { FormLayoutType, FormLayoutColumnType } from '@features/Platform/Templates/FormBuilder/helpers';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useFormAttachments } from '@components/Project/DataBank/DataBankContent/FileList/FileProperties/FormDocsSidebar/FormDocsSidebar';
import { useEffectOnceBy } from '@hooks/useEffectOnceBy';
import { useSubscribeToFormUpdates } from './useSubscribeToFormUpdates';
import { FormRenderer } from '../FormRenderer';

const debouncedFieldFunctions: { [key: number]: any } = {};

const getFormInitialValues = (form: TForm, project: ProjectDetail) => {
  const initialValues: { [key: string]: any } = {};

  const getFieldValue = (formLayout: FormLayout) => {
    const isProject = formLayout?.column?.type === FormLayoutColumnType.Project;

    let value;

    if (isProject) {
      const column = formLayout.column.projectColumn;

      // property is deleted
      if (!column) {
        return undefined;
      }

      value = column.isAdditional
        ? project.additional[column.id]
        : get(project, column.displayValuePath ?? column.objectName ?? column.mappedName);
    } else {
      value = formLayout.column.projectPropertyValuesByColumnId[0]
        ? getAdditionalPropertyValue({
            ...formLayout.column.projectPropertyValuesByColumnId[0],
            column: formLayout.column
          })
        : null;
    }

    return value;
  };

  form.formLayouts.forEach((formLayout) => {
    if (formLayout.type === FormLayoutType.GROUP) {
      formLayout.childFormLayouts.forEach((childFormLayout) => {
        if (childFormLayout.column) {
          initialValues[`field_${childFormLayout.column.id}`] = getFieldValue(childFormLayout);
        }
      });
    } else if (formLayout.column) {
      initialValues[`field_${formLayout.column.id}`] = getFieldValue(formLayout);
    }
  });

  return initialValues;
};

const FormInstanceForm = ({
  form,
  project,
  isFullScreen = false
}: {
  form: TForm;
  project: ProjectDetail;
  isFullScreen?: boolean;
}) => {
  const { mutateAsync: updateFieldValue } = useUpdateFormFieldValue();
  const queryClient = useQueryClient();

  const defaultValues = useMemo(() => getFormInitialValues(form, project), [form, project]);

  const formColumnsById = useMemo(() => {
    const result: { [key: number]: ProjectProperty } = {};

    const getFormColumnsById = (formLayouts: FormLayout[]): void => {
      formLayouts.forEach((formLayout) => {
        if (formLayout.column) {
          result[formLayout.column.id] = formLayout.column;
        }

        if (formLayout.type === FormLayoutType.GROUP) {
          getFormColumnsById(formLayout.childFormLayouts);
        }
      });
    };

    getFormColumnsById(form.formLayouts);

    return result;
  }, [form]);

  const {
    form: { control, watch, setValue, setError, getValues }
  } = useForm({
    defaultValues,
    onSubmit: () => Promise.resolve()
  });

  const handleFieldUpdatedEvent = useCallback(
    async (fieldId: number, response: any) => {
      const fieldName = `field_${fieldId}`;
      // additional project field or form field
      if (response.value && typeof response.value === 'object' && 'column' in response.value) {
        const { column, fileValues, ...projectPropertyValuesByColumnId } = response.value;

        if (!column) {
          return;
        }

        const value = getAdditionalPropertyValue({
          ...projectPropertyValuesByColumnId,
          files: fileValues,
          column
        });

        if (response.action === 'add') {
          const previousValue = getValues(fieldName) ?? [];

          setValue(fieldName, [...previousValue, ...(value ?? [])]);
        } else if (response.action === 'remove') {
          const previousValue = getValues(fieldName) ?? [];

          setValue(
            fieldName,
            previousValue.filter((item) => {
              if (column.type === PropertyType.File) {
                return !((value || []) as File[]).some((file) => file.id === item.id);
              }

              return item !== value;
            })
          );
        } else {
          setValue(fieldName, value);
        }
      } else {
        setValue(fieldName, response.value);
      }
    },
    [setValue, getValues]
  );

  const { clientSocketId, collaborators, onFieldEnter, onFieldLeave } = useSubscribeToFormUpdates(
    form.id,
    handleFieldUpdatedEvent
  );

  const handleOnFieldLeave = useCallback(
    async (fieldId: number) => {
      onFieldLeave(fieldId);
      debouncedFieldFunctions[fieldId]?.flush();
    },
    [onFieldLeave]
  );

  const submitFieldDebounced = useCallback(
    async (fieldId: number, value: any, socketId: string) => {
      if (formColumnsById[fieldId]?.projectColumn?.type === PropertyType.Person) {
        await updateFieldValue({
          clientSocketId: socketId,
          formId: form.id,
          dto: { fieldId, value: value?.id ?? null, action: 'replace' },
          projectId: formColumnsById[fieldId].projectColumn ? project.id : undefined
        });
      } else if (
        formColumnsById[fieldId]?.projectColumn?.type === PropertyType.File ||
        formColumnsById[fieldId]?.type === PropertyType.File
      ) {
        await updateFieldValue({
          clientSocketId: socketId,
          formId: form.id,
          projectId: formColumnsById[fieldId].projectColumn ? project.id : undefined,
          dto: {
            fieldId,

            value: (value ?? []).map((file) => (typeof file === 'number' ? file : file.id)),
            action: 'replace'
          }
        });
      } else {
        const response = await updateFieldValue({
          clientSocketId: socketId,
          formId: form.id,
          dto: { fieldId, value, action: 'replace' },
          projectId: formColumnsById[fieldId].projectColumn ? project.id : undefined
        });

        if (!response.success) {
          setError(`field_${fieldId}`, { message: response.error });
        }
      }
    },
    [form.id, formColumnsById, updateFieldValue, project.id, setError]
  );

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type !== 'change') {
        return;
      }

      const nameStr = name as string;
      if (nameStr.startsWith('field_')) {
        const fieldId = +nameStr.replace('field_', '');

        if (!debouncedFieldFunctions[fieldId]) {
          debouncedFieldFunctions[fieldId] = debounce((value, currentClientSocketId) => {
            submitFieldDebounced(fieldId, value, currentClientSocketId);
          }, 10000);
        }

        debouncedFieldFunctions[fieldId](values[name], clientSocketId);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, submitFieldDebounced, clientSocketId]);

  useEffect(() => {
    return () => {
      Object.values(debouncedFieldFunctions).forEach((func) => {
        func.flush();
      });
      queryClient.invalidateQueries([ReactQueryKey.FormFull, form.id]);
      queryClient.invalidateQueries([ReactQueryKey.RecordDetail, project.id]);
    };
  }, [queryClient, form.id, project.id]);

  const { openDrawer } = useDrawersContext();

  const formAttachments = useFormAttachments(form.file?.id);

  const allAttachmentIds = useMemo(() => {
    return formAttachments.reduce((acc, attachment) => {
      return [...acc, ...attachment.files.map((file) => file.id)];
    }, [] as number[]);
  }, [formAttachments]);

  const handleFileClick = useCallback(
    (fileId: number) => {
      openDrawer(DrawerEntity.FILE, fileId, allAttachmentIds);
    },
    [openDrawer, allAttachmentIds]
  );

  return (
    <FormRenderer
      collaborators={collaborators}
      onFieldFocus={onFieldEnter}
      onFieldBlur={handleOnFieldLeave}
      control={control}
      form={form}
      isFullScreen={isFullScreen}
      onFileClick={handleFileClick}
    />
  );
};

interface Props {
  formId: number;
  projectId: number;
  isFullScreen?: boolean;
}

export const FormInstance = ({ formId, projectId, isFullScreen = false }: Props) => {
  const { data: form } = useFullForm(formId as number);
  const { data: project, isRefetching } = useRecordDetail(projectId);

  const [isProjectRefetching, setIsProjectRefetching] = useState(isRefetching);

  useEffectOnceBy(
    () => {
      setIsProjectRefetching(false);
    },
    () => project && !isRefetching,
    [project, isRefetching]
  );

  if (!form || !project?.id || isProjectRefetching) {
    return null;
  }

  return <FormInstanceForm isFullScreen={isFullScreen} project={project} form={form} />;
};
