import { fonts } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  > div {
    height: 100%;
    width: 100%;
  }

  &&& {
    .vjs-has-started:not(.vjs-playing) .vjs-big-play-button {
      display: block;
    }

    .video-js {
      .vjs-current-time {
        padding-right: 0;
      }

      .vjs-duration {
        padding-left: 0;
      }

      .vjs-current-time,
      .vjs-time-divider,
      .vjs-duration {
        display: block;
      }

      .vjs-time-control {
        min-width: auto;
        line-height: 24px;
        font-size: 14px;
        font-family: ${fonts.sans};
      }

      .vjs-current-time,
      .vjs-duration {
        min-width: 44px;
      }

      .vjs-time-divider {
        padding: 0 4px;
      }

      .vjs-control-bar {
        height: 72px;
        padding-top: 32px;
        padding-bottom: 16px;
        padding-left: 8px;
        padding-right: 8px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9));
        background-color: transparent;
      }

      button.vjs-control {
        width: 24px;
        height: 24px;
        margin: 0 8px;
      }

      .vjs-playback-rate .vjs-playback-rate-value {
        font-size: 14px;
        line-height: 24px;
      }

      .vjs-play-control,
      .vjs-mute-control,
      .vjs-fullscreen-control {
        .vjs-icon-placeholder:before {
          width: 24px;
          height: 24px;
          content: '';
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: center;
        }
      }

      .vjs-play-control .vjs-icon-placeholder:before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4yMiAyLjExYTEgMSAwIDAgMC0xLjA0Ni41NDFsLS4wNjguMTM4QTEgMSAwIDAgMCA0IDMuMjM2VjIxYTEgMSAwIDAgMCAxLjU0Ljg0MWwxNC05YTEgMSAwIDAgMCAwLTEuNjgyTDUuNzIgMi4yNzRhMSAxIDAgMCAwLS4zOS0uMTQ3bC0uMTEtLjAxN3pNMTcuMTUgMTIgNiA0LjgzMnYxNC4zMzZMMTcuMTUgMTJ6IiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=');
      }

      .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItcGF1c2UiPjxyZWN0IHg9IjYiIHk9IjQiIHdpZHRoPSI0IiBoZWlnaHQ9IjE2Ij48L3JlY3Q+PHJlY3QgeD0iMTQiIHk9IjQiIHdpZHRoPSI0IiBoZWlnaHQ9IjE2Ij48L3JlY3Q+PC9zdmc+');
      }

      .vjs-mute-control .vjs-icon-placeholder:before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJtNy42NSA4IDQuNzI1LTMuNzhMMTMgNWgxdjE0YTEgMSAwIDAgMS0xLjYyNS43ODFsLTQuNzI2LTMuNzhINGExIDEgMCAwIDEtMS0xVjlhMSAxIDAgMCAxIDEtMWgzLjY1ek0xMiA3LjA4IDguNjI1IDkuNzgyYTEgMSAwIDAgMS0uNjI1LjIySDV2NGgzYTEgMSAwIDAgMSAuNjI1LjIxOEwxMiAxNi45MlY3LjA4MnpNMTYuODMyIDcuNzUzYTEgMSAwIDAgMSAxLjQxNCAwIDYgNiAwIDAgMSAwIDguNDg0IDEgMSAwIDAgMS0xLjQxNC0xLjQxNCA0IDQgMCAwIDAgMC01LjY1NiAxIDEgMCAwIDEgMC0xLjQxNHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==');
      }

      .vjs-fullscreen-control {
        margin-left: auto !important;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiA1YTMgMyAwIDAgMSAzLTNoM2ExIDEgMCAwIDEgMCAySDVhMSAxIDAgMCAwLTEgMXYzYTEgMSAwIDAgMS0yIDBWNXptMTMtMmExIDEgMCAwIDEgMS0xaDNhMyAzIDAgMCAxIDMgM3YzYTEgMSAwIDEgMS0yIDBWNWExIDEgMCAwIDAtMS0xaC0zYTEgMSAwIDAgMS0xLTF6TTMgMTVhMSAxIDAgMCAxIDEgMXYzYTEgMSAwIDAgMCAxIDFoM2ExIDEgMCAxIDEgMCAySDVhMyAzIDAgMCAxLTMtM3YtM2ExIDEgMCAwIDEgMS0xem0xOCAwYTEgMSAwIDAgMSAxIDF2M2EzIDMgMCAwIDEtMyAzaC0zYTEgMSAwIDEgMSAwLTJoM2ExIDEgMCAwIDAgMS0xdi0zYTEgMSAwIDAgMSAxLTF6IiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=');
      }

      .vjs-load-progress div {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 4px;
      }

      .vjs-play-progress {
        background-image: linear-gradient(to right, #009688 0%, #19c9b9 100%);
        border-radius: 4px;
      }

      .vjs-progress-holder {
        height: 4px;
        border-radius: 4px;
      }

      .vjs-load-progress {
        border-radius: 4px;
      }

      .vjs-progress-control {
        position: absolute;
        height: 24px;
        top: 0px;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0;
        padding: 0 8px;
      }

      .vjs-volume-control.vjs-volume-vertical {
        height: 90px;
        transition: none;
        bottom: 90px;
      }

      .vjs-menu .vjs-menu-content {
        height: 90px;
        max-height: none;
        bottom: 10px;
      }

      .vjs-menu {
        z-index: 1;
      }

      .vjs-volume-panel:active,
      .vjs-volume-panel.vjs-hover {
        .vjs-volume-control.vjs-volume-vertical {
          left: -34px;
          z-index: 1;
        }
      }

      .vjs-volume-bar.vjs-slider-vertical {
        height: 65px;
      }

      .vjs-big-play-button {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 0;

        background-color: rgba(0, 150, 136, 60%);

        .vjs-icon-placeholder:before {
          width: 24px;
          height: 24px;
          content: '';
          top: calc(50% - 12px);
          left: calc(50% - 9px);
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: center;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4yMiAyLjExYTEgMSAwIDAgMC0xLjA0Ni41NDFsLS4wNjguMTM4QTEgMSAwIDAgMCA0IDMuMjM2VjIxYTEgMSAwIDAgMCAxLjU0Ljg0MWwxNC05YTEgMSAwIDAgMCAwLTEuNjgyTDUuNzIgMi4yNzRhMSAxIDAgMCAwLS4zOS0uMTQ3bC0uMTEtLjAxN3pNMTcuMTUgMTIgNiA0LjgzMnYxNC4zMzZMMTcuMTUgMTJ6IiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=');
        }
      }

      &:hover .vjs-big-play-button {
        background-color: rgba(0, 150, 136, 0.8);
      }
    }
  }
`;
