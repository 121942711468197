import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { SystemStats } from '@hooks/systems/types';
import { ChartMilestone, chartMilestoneConfig } from '@hooks/systems/constants';
import { apiErrorHandler } from '@utils';
import fleetChartsAPI, { ChartDTO, Meter, SystemTotalEnergy, SystemTotalPower } from '@services/api/fleetChartsAPI';
import { groupBy, mapValues } from 'lodash';

export const useSystemsStats = (systemdsIds: number[]) =>
  useQuery<{ [stat in keyof SystemStats]: { [uuid: string]: number } }, Error>({
    queryKey: [ReactQueryKey.System, 'useSystemsStats', systemdsIds],
    queryFn: async () => {
      try {
        const yesterdayInterval = chartMilestoneConfig[ChartMilestone.yesterday].interval();
        const weekInterval = chartMilestoneConfig[ChartMilestone.week].interval();
        const monthInterval = chartMilestoneConfig[ChartMilestone.month].interval();
        const yearInterval = chartMilestoneConfig[ChartMilestone.year].interval();
        const lifetimeInterval = chartMilestoneConfig[ChartMilestone.lifetime].interval();

        const chartsDtos: ChartDTO[] = [
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Production,
            period: '1 day',
            startAt: yesterdayInterval?.start.toISO(),
            endBefore: yesterdayInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Production,
            period: '1 day',
            startAt: weekInterval?.start.toISO(),
            endBefore: weekInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Production,
            period: '1 day',
            startAt: monthInterval?.start.toISO(),
            endBefore: monthInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Production,
            period: '1 year',
            startAt: yearInterval?.start.toISO(),
            endBefore: yearInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Production,
            period: '1 year',
            startAt: lifetimeInterval?.start.toISO(),
            endBefore: lifetimeInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Consumption,
            period: '1 day',
            startAt: yesterdayInterval?.start.toISO(),
            endBefore: yesterdayInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Consumption,
            period: '1 day',
            startAt: weekInterval?.start.toISO(),
            endBefore: weekInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Consumption,
            period: '1 day',
            startAt: monthInterval?.start.toISO(),
            endBefore: monthInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Consumption,
            period: '1 year',
            startAt: yearInterval?.start.toISO(),
            endBefore: yearInterval?.end.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalSystem',
            meter: Meter.Consumption,
            period: '1 year',
            startAt: lifetimeInterval?.start.toISO(),
            endBefore: lifetimeInterval?.end.toISO()
          },
          {
            metric: 'power',
            kind: 'totalSystem',
            meter: Meter.Production,
            period: '1 year',
            startAt: lifetimeInterval?.start.toISO(),
            endBefore: lifetimeInterval?.end.toISO()
          }
        ];

        const { data: stats } = await fleetChartsAPI.getSystemsCharts({ systems: systemdsIds, charts: chartsDtos });

        const [
          productionYesterday,
          productionWeek,
          productionMonth,
          productionYear,
          productionLifetime,
          consumptionYesterday,
          consumptionWeek,
          consumptionMonth,
          consumptionYear,
          consumptionLifetime,
          peakPower
        ] = stats as [
          productionYesterday: SystemTotalEnergy[],
          productionWeek: SystemTotalEnergy[],
          productionMonth: SystemTotalEnergy[],
          productionYear: SystemTotalEnergy[],
          productionLifetime: SystemTotalEnergy[],
          consumptionYesterday: SystemTotalEnergy[],
          consumptionWeek: SystemTotalEnergy[],
          consumptionMonth: SystemTotalEnergy[],
          consumptionYear: SystemTotalEnergy[],
          consumptionLifetime: SystemTotalEnergy[],
          peakPower: SystemTotalPower[]
        ];

        return {
          productionYesterday: mapValues(
            groupBy(productionYesterday, (energy) => energy.system),
            ([point]) => point.value
          ),
          productionWeek: mapValues(
            groupBy(productionWeek, (energy) => energy.system),
            ([point]) => point.value
          ),
          productionMonth: mapValues(
            groupBy(productionMonth, (energy) => energy.system),
            ([point]) => point.value
          ),
          productionYear: mapValues(
            groupBy(productionYear, (energy) => energy.system),
            ([point]) => point.value
          ),
          productionLifetime: mapValues(
            groupBy(productionLifetime, (energy) => energy.system),
            ([point]) => point.value
          ),
          consumptionYesterday: mapValues(
            groupBy(consumptionYesterday, (energy) => energy.system),
            ([point]) => point.value
          ),
          consumptionWeek: mapValues(
            groupBy(consumptionWeek, (energy) => energy.system),
            ([point]) => point.value
          ),
          consumptionMonth: mapValues(
            groupBy(consumptionMonth, (energy) => energy.system),
            ([point]) => point.value
          ),
          consumptionYear: mapValues(
            groupBy(consumptionYear, (energy) => energy.system),
            ([point]) => point.value
          ),
          consumptionLifetime: mapValues(
            groupBy(consumptionLifetime, (energy) => energy.system),
            ([point]) => point.value
          ),
          peakPower: mapValues(
            groupBy(peakPower, (energy) => energy.system),
            ([point]) => point.value
          )
        } as { [stat in keyof SystemStats]: { [uuid: string]: number } };
      } catch (error) {
        throw apiErrorHandler('Error fetching systems', error);
      }
    },
    enabled: systemdsIds?.length > 0,
    keepPreviousData: true,
    initialData: {
      productionToday: {},
      productionYesterday: {},
      productionWeek: {},
      productionMonth: {},
      productionYear: {},
      productionLifetime: {},
      consumptionToday: {},
      consumptionYesterday: {},
      consumptionWeek: {},
      consumptionMonth: {},
      consumptionYear: {},
      consumptionLifetime: {},
      peakPower: {}
    }
  });
