import { navigate } from 'gatsby';

import { getUserSettings } from '@utils/utils';
import { eventTracking } from '@services/eventTracking';
import UserPreferences from '@services/UserPreferences';
import * as actionTypes from '../../actions/actionTypes/actionTypes';
import IAuthenticationReducerTypes from './types';
import { subscribeForNotification } from '../../../hooks';
import { userResponseToAuthUser } from '../../../adapters/UserDataAdapters/userDataAdapter';

declare global {
  interface Window {
    Vitally: any;
    heap: any;
    chmln: any;
    ChurnZero: any;
  }
}

export const initialState: IAuthenticationReducerTypes = {
  user: {
    userId: 0,
    name: '',
    email: '',
    avatarUrl: '',
    roles: [],
    company: { id: 0, name: '', logoUrl: '' },
    settings: getUserSettings(0)
  },
  loading: false,
  isUserDataLoading: false,
  error: false,
  tokenExpirationTime: 0,
  tokenMailInterval: 0,
  errorMessage: ''
};

const updateUserAndTokenInLocalStorage = (accessToken, refreshToken, userResponse) => {
  const now = new Date().getTime() / 1000;

  // to accommodate inaccuracy of setTimeout and to prevent user make request before renewing expired token(60s)
  const safetyTime = accessToken.expiresIn - 60 > 0 ? 60 : 0;

  localStorage.setItem(
    'token',
    JSON.stringify({
      accessToken: {
        ...accessToken,
        expiresIn: accessToken.expiresIn < now ? accessToken.expiresIn + now - safetyTime : accessToken.expiresIn
      },
      refreshToken: {
        ...refreshToken,
        expiresIn: refreshToken.expiresIn < now ? refreshToken.expiresIn + now : refreshToken.expiresIn
      },
      user: userResponse
    })
  );
};

const formLoginStart = (state, action) => {
  return { ...state, loading: true, error: false, errorMessage: '' };
};

const loginSuccess = (state, action) => {
  const { accessToken, refreshToken, user, method = 'unknown' } = action.payload;
  let newUser = user;
  if (!user) {
    newUser = JSON.parse(localStorage.getItem('token'))?.user;
  }
  if ('password' in newUser) delete newUser.password;

  const { user: userData } = userResponseToAuthUser(newUser);

  // update user info and token in local storage
  updateUserAndTokenInLocalStorage(accessToken, refreshToken, newUser);

  // subscribe for push notification
  subscribeForNotification(accessToken.token, userData.userId);

  // set heap.io user
  setAnalyticsUser({
    ...userData,
    roles: userData.roles,
    company: userData.company
  });

  eventTracking.trackOnceWithinMinutes(
    {
      event: 'USER_LOGIN',
      description: `Login with method: ${method}`,
      customFields: {
        Source: 'Platform'
      }
    },
    5,
    `USER_LOGIN_${userData.userId}`
  );

  /* Important! don't remove */
  if (typeof window !== 'undefined') {
    const locationPath = window.location.pathname;
    if (
      locationPath === '/' ||
      locationPath.includes('/authentication') ||
      locationPath.includes('/invite') ||
      locationPath.includes('/forgot-password')
    ) {
      navigate(`/${userData.company.id}/projects`);
    }
  }

  return {
    ...state,
    user: { ...userData, settings: getUserSettings(userData.userId) },
    loading: false,
    error: false,
    errorMessage: ''
  };
};

const formLoginFailed = (state, action) => {
  const { errorMessage = 'Something unexpected has happened' } = action.error;
  localStorage.setItem('token', '');
  return { ...state, loading: false, error: true, errorMessage };
};

const googleLoginStart = (state, action) => {
  return { ...state, loading: true, error: false, errorMessage: '' };
};

const googleLoginFailed = (state, action) => {
  const { errorMessage = 'Something unexpected has happened' } = action.error;

  localStorage.setItem('token', '');
  return { ...state, loading: false, error: true, errorMessage };
};

const generateResetPasswordTokenStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false,
    tokenMailInterval: 0,
    tokenExpirationTime: 0,
    errorMessage: ''
  };
};

const generateResetPasswordTokenSuccess = (state, action) => {
  const { tokenMailInterval, tokenExpirationTime } = action.payload;
  return {
    ...state,
    loading: false,
    error: false,
    tokenMailInterval,
    tokenExpirationTime,
    errorMessage: ''
  };
};

const generateResetPasswordTokenFailed = (state, action) => {
  const { errorMessage = 'Something unexpected has happened' } = action.error;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    tokenMailInterval: 0,
    tokenExpirationTime: 0
  };
};

const resetPasswordStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false,
    errorMessage: ''
  };
};

const resetPasswordSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: ''
  };
};

const resetPasswordFailed = (state, action) => {
  const { errorMessage = 'Something unexpected has happened' } = action.error;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage
  };
};

const resetPasswordResetState = (state) => {
  return {
    ...state,
    loading: false,
    error: false,
    errorMessage: '',
    tokenMailInterval: 0,
    tokenExpirationTime: 0
  };
};

const companyRegisterStart = (state, action) => {
  return { ...state, loading: true };
};

const companyRegisterSuccess = (state, action) => {
  return { ...state, loading: false };
};

const companyRegisterFailed = (state, action) => {
  return { ...state, loading: false };
};

const authenticationUpdate = (state, action) => {
  const {
    payload: { userResponse, accessToken, refreshToken }
  } = action;

  if (userResponse && accessToken && refreshToken) {
    const { user } = userResponseToAuthUser(userResponse);
    updateUserAndTokenInLocalStorage(accessToken, refreshToken, userResponse);
    return {
      ...state,
      user: { ...user, settings: getUserSettings(user.userId) }
    };
  }

  return { ...state };
};

const logOut = (state, action) => {
  navigate('/authentication');
  if (state.checkTokenTimeout) clearTimeout(state.checkTokenTimeout);
  return {
    ...initialState,
    checkTokenTimeout: undefined
  };
};

const refreshTokenTimeoutUpdate = (state, action) => {
  const { checkTokenTimeout } = action;
  if (state.checkTokenTimeout) clearTimeout(state.checkTokenTimeout);

  return { ...state, checkTokenTimeout };
};

const authenticationReducer = (state = initialState, action): IAuthenticationReducerTypes => {
  switch (action.type) {
    case actionTypes.FORM_LOGIN_START:
      return formLoginStart(state, action);
    case actionTypes.FORM_LOGIN_FAILED:
      return formLoginFailed(state, action);
    case actionTypes.GOOGLE_LOGIN_START:
      return googleLoginStart(state, action);
    case actionTypes.GOOGLE_LOGIN_FAILED:
      return googleLoginFailed(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.COMPANY_REGISTER_START:
      return companyRegisterStart(state, action);
    case actionTypes.COMPANY_REGISTER_SUCCESS:
      return companyRegisterSuccess(state, action);
    case actionTypes.COMPANY_REGISTER_FAILED:
      return companyRegisterFailed(state, action);
    case actionTypes.AUTHENTICATION_UPDATE:
      return authenticationUpdate(state, action);
    case actionTypes.LOGOUT:
      return logOut(state, action);
    case actionTypes.GENERATE_RESET_PASSWORD_TOKEN_START:
      return generateResetPasswordTokenStart(state, action);
    case actionTypes.GENERATE_RESET_PASSWORD_TOKEN_SUCCESS:
      return generateResetPasswordTokenSuccess(state, action);
    case actionTypes.GENERATE_RESET_PASSWORD_TOKEN_FAILED:
      return generateResetPasswordTokenFailed(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAILED:
      return resetPasswordFailed(state, action);
    case actionTypes.RESET_PASSWORD_RESET_STATE:
      return resetPasswordResetState(state, action);
    case actionTypes.REFRESH_TOKEN_TIMEOUT_UPDATE:
      return refreshTokenTimeoutUpdate(state, action);
    default:
      return state;
  }
};

const setAnalyticsUser = (user) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const accountType = ((roles) => {
    if (roles.length === 0) {
      return 'None';
    }

    switch (roles[0]?.name) {
      case 'WORKER':
        return 'Worker';
      case 'PROJECT_MANAGER':
        return 'Project Manager';
      case 'ADMIN':
        return 'Admin';
      default:
        return roles[0].name || 'Unknown';
    }
  })(user.roles);

  window?.heap?.identify(user.email);
  window?.heap?.addUserProperties({
    email: user.email,
    company: user.company.name,
    firstName: user.firstName,
    lastName: user.lastName,
    accountType
  });

  window?.Vitally?.account({
    accountId: user.company.id,
    traits: {
      name: user.company.name // TODO: Add more Account traits
    }
  });
  window?.Vitally?.user({
    userId: user.userId,
    accountId: user.company.id,
    traits: {
      name: user.name,
      email: user.email,
      role: user.roles[0]?.name // Adding user's role for triage in #voice-of-customer
    }
  });

  window?.chmln?.identify(user.userId, {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    role: accountType,
    company: {
      id: user.company.companyId,
      name: user.company.name
    }
  });

  eventTracking.setUser(user.company, user ? { ...user, accountType } : {});
};

export default authenticationReducer;
