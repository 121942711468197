import { rehydrateSearch } from '@utils';
import { Search } from '@types';
import { FavProject, ProjectStatus, SearchFrom } from '@components/Project/ProjectView/types';
import { ProjectViewReducer, SelectedProjectProp } from './types';

/**
 * @deprecated use {@link useSearch.getDefaultSearch}
 */
export const SEARCH_DEFAULT: Search = {
  groupBy: 0,
  page: 1,
  perPage: 10,
  search: '',
  orderBy: [
    {
      columnId: -5,
      desc: true
    }
  ],
  type: ProjectStatus.active,
  groupFilter: '',
  from: SearchFrom.grid,
  companyId: undefined,
  selectedView: undefined,
  filters: {}
};

export const projectViewInitialState: ProjectViewReducer = {
  search: rehydrateSearch(SEARCH_DEFAULT),
  searchDefault: SEARCH_DEFAULT,
  isLoading: false,
  error: false,
  groups: [],
  isTemplateLoading: false,
  templateList: [],
  isLoadingFavoriteProject: false,
  favoriteProjectList: [] as FavProject[],
  totalFavoriteProject: 0,
  favoriteSearch: {
    page: 1,
    perPage: 10,
    sortCol: 'createdAt',
    sortDesc: true
  },
  uploading: false,
  selectedProject: {} as SelectedProjectProp,
  fetchingOne: false,
  views: []
};
