import { ALLOWED_FORMATS, compressImage, extractExtension } from '@utils/compressImage';
import api from '@services/api/databankApi';
import { post } from '@services/api/base';

import { getUploadSingleFileUrl } from '../service.config';

type UploadQuery = {
  isPrivate: boolean;
  entityId: number;
  projectId: number;
  by: 'project' | 'folder' | 'form' | 'company';
};

export const uploadSingleFile = (
  file: FormData,
  params: {
    companyId: number;
    by?: 'project' | 'folder' | 'form' | 'company';
    id?: number;
  }
) => {
  return post(getUploadSingleFileUrl(params), file);
};

export const uploadCompressedFile = async ({
  companyId,
  formData,
  isPrivate = false,
  projectId,
  entityId,
  by
}: { formData: FormData; companyId: number } & Partial<UploadQuery>): Promise<{ data: File }> => {
  const file = formData.get('file') as File;
  const extension = extractExtension(file);
  const fileIsImage = file.type?.split('/')[0] === 'image' || extension?.toLowerCase() === 'heic';
  const imageIsProcessable = ALLOWED_FORMATS.includes(file.type?.split('/')[1] ?? extension?.toLowerCase());

  if (fileIsImage && imageIsProcessable) {
    const result = await compressImage(file);
    formData.set('file', result);
  }

  if (isPrivate && projectId) {
    return api.uploadFile(projectId, formData);
  }

  return uploadSingleFile(formData, { companyId, by, id: entityId });
};
